body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #0671c9;
  border-radius: 20px;
  border: 3px solid transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #0671c9 transparent;
}

/* Custom scroll bar works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #0671c9;
  border-radius: 20px;
  border: 3px solid transparent;
}

.ant-layout-sider-trigger {
  background: #0571c9 !important;
}

.bg-content {
  background: url(../../public/images/background/hero-bg.png) 0% 0% / cover no-repeat;
}

.ck-content {
  min-height: 200px;
}
