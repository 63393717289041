.login-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .login-block {
    width: 500px;
    max-width: 500px;
    padding: 32px;
    border: 1px solid #cde6f9;
    border-radius: 20px;
    background: #fdfdfd;
    opacity: 0.8;

    .login-title {
      margin-bottom: 30px;
      font-size: 30px;
      text-align: center;
    }
  }

  .login-form {
  }
}
